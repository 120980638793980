<template>
  <div class="container">
    <ul class="panel">
      <li>
        <p class="p1">{{ queryNum }}</p>
        <p class="p2">当月扫码次数（次）</p>
      </li>
      <li>
        <p class="p1">{{ currentQueryNum }}</p>
        <p class="p2">累计扫码次数（次）</p>
      </li>
    </ul>
    <div class="m-list">
      <ul class="list__main">
        <li v-for="(item, index) in dataList" :key="index">
          <div class="list__item">
            <div class="list__left">
              <span class="p1">扫码</span>
              <span class="p1">+1</span>
            </div>
            <div class="list__right">{{ item.createTime }}</div>
          </div>
        </li>
      </ul>
      <p class="more" v-if="!isMore"><span>已无更多记录</span></p>
      <p class="more" v-else @click="onLoadMore"><span>点击加载更多</span></p>
    </div>
  </div>
</template>

<script>
import {
  selectCountOfScanQrcodePersonByOpenId,
  selectMonthCountOfScanQrcodePersonByOpenId,
  selectPageScanQrcodePersonDetails
} from '@/api/scan-log.js'
import { mapGetters } from 'vuex'

export default {
  name: 'ScanLog',
  data() {
    return {
      queryNum: '',
      currentQueryNum: '',
      filter: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      dataList: []
    }
  },
  computed: {
    ...mapGetters(['projectCode', 'qrCode', 'isLocation']),
    isMore() {
      return this.filter.pageNum * this.filter.pageSize < this.total
    }
  },
  methods: {
    //当月扫码次数
    getSelectCountOfScanQrcodeByQrcode() {
      selectCountOfScanQrcodePersonByOpenId().then(({ data }) => {
        this.queryNum = data
      })
    },
    //累计扫码次数
    getSelectCountOfScanQrcodeByQrcodeAndOpenId() {
      selectMonthCountOfScanQrcodePersonByOpenId().then(({ data }) => {
        this.currentQueryNum = data
      })
    },
    // 获取扫码个人记录
    fetchDataList() {
      selectPageScanQrcodePersonDetails(this.filter).then(({ data }) => {
        this.dataList = this.dataList.concat(data.records)
        this.total = data.total
      })
    },
    onLoadMore() {
      this.filter.pageNum += 1
      this.fetchDataList()
    }
  },
  created() {
    this.getSelectCountOfScanQrcodeByQrcode()
    this.getSelectCountOfScanQrcodeByQrcodeAndOpenId()
    this.fetchDataList()
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .panel {
    display: flex;
    width: 100%;
    height: 230px;
    background: #ededed;
    li {
      width: 50%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .p1 {
      font-size: 52px;
      color: #333;
      font-weight: bold;
    }
    .p2 {
      font-size: 24px;
      color: #666;
    }
  }
  .m-list {
    padding-top: 20px;
    li {
      position: relative;
      padding: 0 24px 0 86px;
      font-size: 24px;
      color: #666;
      height: 72px;
      line-height: 72px;
      .p1 {
        margin-right: 26px;
      }
      &:before {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 46px;
        width: 1px;
        height: 72px;
        background: #ededed;
      }
      &:after {
        content: ' ';
        display: block;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 43px;
        width: 8px;
        height: 8px;
        background: #999;
        border-radius: 50%;
      }
      .list__item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ededed;
      }
    }
    li:first-child {
      &:before {
        top: 36px;
      }
      &:after {
        left: 34px;
        width: 24px;
        height: 24px;
        background: url('../../assets/scan-log-ico.png') no-repeat 0 0 / 100%
          100%;
      }
    }
  }
  .more {
    font-size: 20px;
    color: #666;
    text-align: center;
    padding: 16px 0;
  }
}
</style>
