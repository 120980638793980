import request from '@/utils/request'

//当月扫码次数
export function selectCountOfScanQrcodePersonByOpenId(data) {
  return request({
    url: '/scanQrcodeDetail/selectMonthCountOfScanQrcodePersonByOpenId',
    method: 'post',
    data,
  })
}

//累计扫码次数
export function selectMonthCountOfScanQrcodePersonByOpenId(data) {
  return request({
    url: '/scanQrcodeDetail/selectCountOfScanQrcodePersonByOpenId',
    method: 'post',
    data,
  })
}

//个人扫码记录
export function selectPageScanQrcodePersonDetails(data) {
  return request({
    url: '/scanQrcodeDetail/selectPageScanQrcodePersonDetails',
    method: 'post',
    data
  })
}
